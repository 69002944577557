import axios from 'axios';
import type { ApiResponse, BlindSignup, SetTutorToPatientInBstServiceParams } from 'src/ts';

export const setTutorToPatientInBstService = async ({
  tutorId,
  verifyPatientBirthDate,
}: SetTutorToPatientInBstServiceParams): Promise<string> => {
  let responseData: ApiResponse<BlindSignup>;

  try {
    const response = await axios.patch<ApiResponse<BlindSignup>>('/patients/update-patient-tutor', {
      tutorId: tutorId,
      verifyPatientBirthDate,
    });

    responseData = response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }

  if (!responseData.isOk) throw new Error(responseData.message);

  return responseData.data.contactId;
};
