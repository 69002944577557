import axios from 'axios';
import { ApiResponse, SessionWithBstInfo, UserLogin } from '../../ts';

export const loginService = async ({ username, password, verifyPatientBirthDate }: UserLogin) => {
  username = username.toLowerCase();

  let response;
  try {
    response = await axios.post<ApiResponse<SessionWithBstInfo>>('/auth/login', {
      mobile: username,
      password,
      verifyPatientBirthDate,
    });
  } catch (error: any) {
    let errorMessage = 'errors.loginInvalid';
    if (error?.response?.status === 403) errorMessage = 'errors.updateMail';
    throw new Error(errorMessage);
  }

  if (response.data.isOk !== true) {
    let errorMessage = 'errors.loginInvalid';
    if (response.data.message.toLowerCase() === 'invalid bst') {
      errorMessage = 'pages.login-signup.bstNotValid';
    }
    throw new Error(errorMessage);
  }
  
  return response.data.data;
};
