import { useAtom, useAtomValue } from 'jotai';
import {
  birthDateConfirmationAtom,
  blindSignupTokenAtom,
  getBstInfoAtom,
  selectedBstPatientAtom,
  setTutorToPatientInBstAtom,
  verifyPatientBirthDateAtom,
} from '../../store/global/atoms';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useLayoutEffect } from 'react';
import { MutationOptions } from 'jotai-tanstack-query';
import { SetTutorToPatientInBstServiceParams, VerifyPatientBirthDate } from 'src/ts';
import { useShowErrorPopup } from '../popup';
import { useTranslation } from 'react-i18next';
import { MutateOptions } from '@tanstack/query-core';

const SUPPORTED_PAGES = ['/residencias'];

export const useBlindSignupToken = () => {
  const { t } = useTranslation();
  const { careHomeBst } = useParams();
  const [blindSignupToken, setBlindSignupToken] = useAtom(blindSignupTokenAtom);
  const location = useLocation();
  const bstInfo = useAtomValue(getBstInfoAtom);
  const setPatientTutor = useAtomValue(setTutorToPatientInBstAtom);
  const { showErrorPopup } = useShowErrorPopup();
  const [selectedBstPatient, setSelectedBstPatient] = useAtom(selectedBstPatientAtom);
  const [birthDateConfirmation, setBirthDateConfirmation] = useAtom(birthDateConfirmationAtom);
  const verifyPatientBirthDateService = useAtomValue(verifyPatientBirthDateAtom);

  const verifyPatientBirthDate: VerifyPatientBirthDate | undefined =
    selectedBstPatient == null || birthDateConfirmation === ''
      ? undefined
      : {
          jwt: selectedBstPatient.jwt,
          birthDateConfirmation,
        };

  const isBstValid = bstInfo.isIdle || bstInfo.isPending ? null : bstInfo.data != null;

  const resetBlindSignupToken = () => setBlindSignupToken(undefined);

  useLayoutEffect(() => {
    if (!SUPPORTED_PAGES.some(page => location.pathname.includes(page))) return;
    if (blindSignupToken != null && careHomeBst == null) return;

    setBlindSignupToken(careHomeBst);
  }, [location, careHomeBst]);

  useEffect(() => {
    if (blindSignupToken == null || bstInfo.data != null) return;
    if (bstInfo.isPending) return;

    cleanBstInfo();
    bstInfo.mutate(blindSignupToken);
  }, [blindSignupToken]);

  useEffect(() => {
    if (isBstValid !== false || blindSignupToken == null) return;

    showErrorPopup(t('modal.addPatientFromBst.tokenNotValidError'));
  }, [bstInfo.data, bstInfo.isIdle, bstInfo.isPending]);

  useEffect(() => {
    if (selectedBstPatient == null || bstInfo.data == null) return;
    setSelectedBstPatient(
      curr =>
        bstInfo.data!.contactList.find(patient => patient.contactName === curr!.contactName) ??
        null,
    );
  }, [bstInfo]);

  const handleSetPatientTutor = (
    tutorId: number,
    options?: MutationOptions<string, Error, SetTutorToPatientInBstServiceParams>,
  ) => {
    if (verifyPatientBirthDate == null) return;
    setPatientTutor.mutate({ tutorId, verifyPatientBirthDate }, options);
  };

  const handleVerifyPatientBirthDate = async (
    options?: MutateOptions<boolean, Error, VerifyPatientBirthDate, unknown> | undefined,
  ) => {
    if (verifyPatientBirthDate == null) return;

    verifyPatientBirthDateService.mutate(verifyPatientBirthDate, options);
  };

  const cleanBstInfo = () => {
    bstInfo.reset();
    setBlindSignupToken(undefined);
    setBirthDateConfirmation('');
    setSelectedBstPatient(null);
  };

  return {
    blindSignupToken,

    bstInfo: bstInfo.data,
    isBstInfoLoading: bstInfo.isPending,
    bstInfoError: bstInfo.error,

    isBstValid,

    setBlindSignupToken,
    resetBlindSignupToken,

    setPatientTutorResult: setPatientTutor.data,
    setTutorToPatient: handleSetPatientTutor,
    isSetTutorToPatientLoading: setPatientTutor.isPending,
    setTutorToPatientError: setPatientTutor.error,

    selectedBstPatient,
    setSelectedBstPatient,

    birthDateConfirmation,
    setBirthDateConfirmation,

    verifyPatientBirthDate,
    verifyPatientBirthDateService: handleVerifyPatientBirthDate,
    isVerifyPatientBirthDateServiceLoading: verifyPatientBirthDateService.isPending,
    verifyPatientBirthDateServiceError: verifyPatientBirthDateService.error,

    cleanBstInfo,
  };
};
